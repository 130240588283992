<template>
  <div class="text">
    <h3 class="text__title">1.Введение</h3>
    <p class="text__p">Эта Политика совместно с Пользовательским соглашением об использовании Системы Butler (а также любыми другими документами, упомянутыми в настоящей Политике) описывают порядок сбора, обработки, использования и раскрытия Ваших персональных данных, включая данные, которые мы получаем от Вас, или которые Вы предоставляете нам в процессе пользования сайтом <a href="www.butlerwallet.com">www.butlerwallet.com</a>. Когда Вы связываетесь с нами через данный сайт, заполняете любые формы, или отправляете какие-либо данные с помощью сайта, мы будем собирать, использовать и раскрывать Вашу личную информацию в соответствии с Политикой обработки персональных данных.</p>
    <br>
    <h3 class="text__title">2.Информация о контролёре персональных данных и об инспекторе по защите персональных данных</h3>
    <p class="text__p">“Контролёр персональных данных” — это компания, которая определяет цели и средства обработки персональных данных.</p>
    <p class="text__p">Контролером является компания Butler payments Limited регистрационный номер 122,913, юридический адрес: 34 Princess Margaret Drive, Belize City,</p>
    <p class="text__p">Связаться с нашим Инспектором по защите персональных данных можно по адресу электронной почты: info@butlerwallet.com</p>
    <br>
    <h3 class="text__title">3.Что такое “персональные данные”?</h3>
    <p class="text__p">Общий регламент о защите персональных данных (GDPR) (Регламент ЕС 2016/679) определяет персональные данные как “любую информацию, относящуюся к субъекту данных, то есть идентифицированному или поддающемуся идентификации физическому лицу, то есть лицу, которое можно прямо или косвенно идентифицировать, в частности, посредством ссылки на идентификатор, такой как имя, идентификационный номер, данные о местоположении, онлайновый идентификатор”. Проще говоря, персональные данные — это любая информация о Вас, которая позволяет Вас идентифицировать.</p>
    <p class="text__p">Персональные данные, которые мы собираем и используем, перечислены в данной Политике.</p>
    <br>
    <h3 class="text__title">Какие персональные данные мы собираем и обрабатываем?</h3>
    <p class="text__p">Информация, которую Вы предоставляете нам напрямую:</p>
    <h4 class="text__title">A. Когда вы регистрируетесь</h4>
    <ul class="text__list">
      <li>имя, фамилия;</li>
      <li>адрес электронной почты, хэш пароля.</li>
    </ul>
    <h4 class="text__title">B. Когда вы верифицируете аккаунт</h4>
    <ul class="text__list">
      <li>номер телефона;</li>
      <li>данные документа, удостоверяющего личность: тип документа, страна выдачи, номер, срок действия, информация, встроенная в штрих-коды документа (может варьироваться в зависимости от документа), элементы защиты;</li>
    </ul>
    <h4 class="text__title">D. Когда мы просим вас предоставить информацию о ваших транзакциях, чтобы предотвратить мошенничество и злоупотребление вашим аккаунтом</h4>
    <ul class="text__list">
      <li>информация необходимая для определения источника происхождения средств, например: банковские справки, выписки, пояснения в свободной форме, скриншоты;</li>
      <li>иная информация, которая позволяет определить цель и экономический смысл спорной транзакции;</li>
      <li>информация, которую вы будете заполнять в опроснике, чтобы подтвердить, что вы действуете по собственной воле и понимаете последствия своих действий.</li>
    </ul>
    <h4 class="text__title">E. Когда вы общаетесь с нами</h4>
    <ul>
      <li>контактный адрес электронной почты;</li>
      <li>содержание переписки, файлы, которые вы прикрепляете к сообщениям;</li>
      <li>уникальный идентификатор в системе тикетов;</li>
      <li>технические данные, связанные с вашими сообщениями (включая дату, часовой пояс, окружение и т. д.).</li>
    </ul>
    <h4 class="text__title">F. Информация об операциях</h4>
    <ul>
      <li>данные ваших кошельков, уникальный идентификатор в системе Butler;</li>
      <li>информация о поступлениях и платежах по Вашей учетной записи, включая информацию о дате, сумме, валюте, участниках платежной операции, информацию о текстовых сообщениях, отправляемых и получаемых вместе с платежами, информацию о мерчантах, используемых способах оплаты, технические данные, а также данные геолокации.</li>
      <li>реквизиты банковской карты, которые Вы регистрируете в нашей Системе и используете для пополнения кошелька или вывода: имя владельца карты, срок действия, первые 6 и последние 4 цифры номера карты, и т.д.</li>
    </ul>
    <h4 class="text__title">G. Информация с Вашего устройства</h4>
    <ul>
      <li>При использовании Вами нашего сайта мы можем собирать следующую техническую информацию:</li>
      <li>наименование Вашего Интернет-провайдера (IP-адрес), данные сетевой среды, учетные данные, информация о типе браузера и его настройках, информация о часовом поясе, используемом устройстве, уникальном идентификаторе устройства, размер экрана, информация о мобильной сети, мобильной операционной системе и виде мобильного браузера, а также информация о дате, времени и длительности сеанса.</li>
    </ul>
    <h4 class="text__title">E. Информация, которую мы можем получить от третьих лиц</h4>
    <p class="text__p">Мы также можем получить Ваши персональные данные через третьих лиц, включая, в частности, следующую информацию:</p>
    <ul class="text__list">
      <li>информация, полученная компанией, входящей в Группу компаний Butler;</li>
      <li>информация от платежных систем, платежных провайдеров;</li>
      <li>информация, полученная платежной системой (например, Visa, Mastercard и т. д.), организациями по борьбе с мошенничеством, бюро кредитных историй, органами исполнительной власти и правоохранительными органами;</li>
      <li>информация, полученная из общедоступных источников, таких как реестры компаний, сайты, предназначенные для углубленной проверки контрагентов;</li>
    </ul>
    <br>
    <h3 class="text__title">Правовые основания для использования ваших персональных данных</h3>
    <p class="text__p">Мы осуществляем обработку Ваших персональных данных только при наличии на то законных оснований. Для обработки персональных данных могут иметься следующие правовые основания:</p>
    <ul class="text__list">
      <li>Исполнение договора в Ваших интересах</li>
      <li>Наше обязательство исполнять требования законодательства</li>
      <li>Наши законные интересы, учитывая ваши права, интересы и ожидания</li>
      <li>Ваше согласие</li>
    </ul>
    <h5 class="text__title">A) Исполнение договора в ваших интересах</h5>
    <p class="text__p">Мы будем использовать Ваши персональные данные для исполнения наших обязательств по заключенному с Вами договору.</p>
    <p class="text__p">Мы будем использовать Ваши персональные данные для проверки и подтверждения Вашей личности для создания Вашей учетной записи и ее администрирования.</p>
    <p class="text__p">Мы будем использовать Ваши персональные данные для обработки операций, которые Вы совершаете с использованием Вашего аккаунта Butler и (или) Butler карты, а также для выполнения Ваших инструкций по Вашей учетной записи: перевод денежных средств, пополнение кошелька или вывод средств.</p>
    <p class="text__p">Мы будем предоставлять Вам важную информацию об имеющихся продуктах и услугах, а также оказывать необходимую техническую и клиентскую поддержку и.</p>
    <h5 class="text__title">Б) Наше обязательство исполнять требования законодательства</h5>
    <p class="text__p">Мы используем Ваши персональные данные для выполнения наших законных обязательств, в частности законодательства о противодействии отмывания доходов, полученных преступным путем и финансирования терроризма (AML).</p>
    <p class="text__p">Также мы можем обрабатывать Ваши данные в случаях, когда мы обязаны оказать содействие органам власти в проведении расследований или раскрыть информацию на основании решания суда в соответствии с требованиями применимого законодательства.</p>
    <h5 class="text__title">В) Наши законные интересы</h5>
    <p class="text__p">Мы можем использовать Ваши персональные данные чтобы анализировать, как клиенты используют наши сервисы и в дальнейшем их качество, работу и функционал сайта. Также мы можем использовать ваши данные, чтобы уведомлять Вас для об изменениях в нашей политике и условиях использования сервисами.</p>
    <p class="text__p">Мы можем собирать и сохранять Ваши персональные данные в целях управления рисками, а также для выявления и предотвращения мошеннических и иных незаконных или запрещенных действий.</p>
    <p class="text__p">Мы можем отслеживать и фиксировать все направляемые Вам и получаемые от Вас сообщения для целей учета, улучшения качества наших услуг и продуктов, а также в целях обучения сотрудников.</p>
    <h5 class="text__title">Г) Ваше согласие</h5>
    <p class="text__p">С Вашего явно выраженного согласия мы можем использовать Ваши персональные данные для связи с Вами посредством электронной почты в целях предоставления Вам информации о наших продуктах и услугах, аналитики использования продукта и повышения эффективности маркетинговых рассылок, а также в иных коммерческих целях. Вы можете отозвать такое согласие в любой момент.</p>
    <br>
    <h3 class="text__title">Автоматизированное принятие решений</h3>
    <p class="text__p">Мы не используем методы автоматизированного принятия решений в процессах, которые могут отразиться на предоставляемых Вам услугах. Проверка Вашего профиля пользователя в целях оценки рисков или предотвращения мошеннических действий осуществляется вручную.</p>
    <br>
    <h3 class="text__title">Файлы cookie</h3>
    <p class="text__p">Информация о том, что собой представляют файлы cookie и как мы их используем, представлена в нашей Политике использования файлов cookie.</p>
    <br>
    <h3 class="text__title">Как мы обеспечиваем безопасность данных?</h3>
    <p class="text__p">Мы стремимся обеспечить защиту Вашей личной информации. Нами принимаются различные меры для обеспечения безопасности Вашей личной информации в процессе размещения Вами заявки или ввода, отправки Вами своей личной информации или обращении к ней.</p>
    <p class="text__p">Мы используем методы шифрования данных и процедуры аутентификации для предотвращения несанкционированного доступа к нашей системе и Вашим данным.</p>
    <p class="text__p">Вся предоставляемая конфиденциальная информация передается по протоколу Secure Socket Layer (SSL).</p>
    <p class="text__p">Права доступа к Вашим данным предоставляются только тем нашим сотрудникам, которым такой доступ необходим для выполнения их должностных обязанностей. Мы регулярно проводим обучение наших сотрудников и рассказываем им о важности сохранения конфиденциальности и секретности персональных данных.</p>
    <p class="text__p">Мы дорабатываем наши процедуры безопасности, чтобы обеспечить соответствие самым строгим требованиям передовых отраслевых стандартов, тем самым обеспечивая высокий уровень защиты Ваших персональных данных.</p>
    <p class="text__p">Со своей стороны Вы также можете придерживаться ряда правил. Всегда используйте надежный пароль и не используйте один и тот же пароль для нескольких учетных записей. Никому не сообщайте свой пароль. Наши представители никогда не запрашивают пароли пользователей, поэтому Вам следует с осторожностью относиться ко всем подобным просьбам и сообщать нам о них по адресу электронной почты info@butlerwallet.com</p>
    <br>
    <h3 class="text__title">Как долго мы храним ваши данные?</h3>
    <p class="text__p">Ваши персональные данные будут храниться нами не дольше, чем это необходимо для конкретной цели, для которых они были собраны, за исключением случаев, когда более длительное хранение требуется по закону. Данные, собранные в целях соблюдения AML законодательства и противодействия мошенничеству, будут храниться в течение 5 лет с момента удаления Вашего аккаунта. Когда цели использования собранной информации будут достигнуты и/или срок хранения, установленный законом, истечет, соответствующая информация будет автоматически удалена без возможности восстановления.</p>
    <br>
    <h3 class="text__title">Трансграничная передача данных</h3>
    <p class="text__p">В связи с тем, что некоторые наши партнеры, а также некоторые наши работники находятся за пределами Европейской экономической зоны (ЕЭЗ), собираемые нами персональные данные могут быть переданы за пределы Европейской экономической зоны и храниться там. Мы стремимся надлежащим образом защитить Ваши персональные данные при их передаче получателям за пределами ЕЭЗ и предпринимаем все разумно необходимые меры для обеспечения безопасного обращения с Вашими данными в соответствии с настоящей Политикой конфиденциальности. По условиям заключенного с ними договора, такие третьи лица обязаны обеспечивать такой же уровень защиты Ваших данных в соответствии с положениями Общего регламента о защите персональных данных. Персональные данные граждан РФ хранятся на территории РФ в соответствиями с требованиями законодательства.</p>
    <br>
    <h3 class="text__title">Ссылки на сторонние сайты</h3>
    <p class="text__p">Время от времени мы можем предлагать на нашем сайте сторонние продукты или услуги, или публиковать информацию о них. Такие сторонние сайты имеют собственные политики конфиденциальности, не связанные с нашей Политикой конфиденциальности. Поэтому мы не несем никакой ответственности за содержание и деятельность таких сторонних сайтов. Тем не менее, мы заботимся о репутации нашего сайта и будем рады получить Ваши комментарии и замечания о таких сторонних сайтах.</p>
    <p class="text__p">Пожалуйста, перед предоставлением таким сайтам каких бы то ни было персональных данных — ознакомьтесь с их политикой конфиденциальности.</p>
    <br>
    <h3 class="text__title">Права субъекта данных</h3>
    <p class="text__p">Согласно Общему регламенту о защите персональных данных Вы имеете право контролировать способ обработки Ваших персональных данных. У Вас есть следующие права:</p>
    <p class="text__p">Право на получение информации. Вы имеете право на получение информации об обработке (сборе и использовании) Ваших персональных данных.</p>
    <p class="text__p">Право доступа. Вы имеете право запрашивать подтверждение относительно того, обрабатываем ли мы относящиеся к Вам персональные данные. Вы также можете запросить информацию о том, как мы собираем, передаем и используем Вашу личную информацию.</p>
    <p class="text__p">Право на уточнение. Вы имеете право проверять свои персональные данные и вносить исправления в устаревшие или неточные персональные данные.</p>
    <p class="text__p">Право на удаление данных (“право быть забытым”). Вы имеете право требовать от нас удаления Ваших персональных данных, если отсутствует объективная необходимость в обработке таких персональных данных для легитимных деловых целей, предусмотренных настоящей Политикой или действующим законодательством.</p>
    <p class="text__p">Право на ограничение обработки и право на возражение. Вы имеете право требовать от нас ограничить обработку Ваших персональных данных, заявить возражение против обработки своих персональных данных, а также возражение против использования методов автоматизированного принятия решений.</p>
    <p class="text__p">Право на переносимость данных. При наличии технической возможности мы, по Вашему требованию, предоставим Вам Ваши персональные данные или же передадим их другому контролеру персональных данных в структурированном, повсеместно используемом и машиночитаемом формате.</p>
    <p class="text__p">Право на отзыв согласия. В тех случаях, когда мы специально запросили Ваше согласие на обработку Ваших персональных данных, и у нас нет никакого иного законного основания для обработки Ваших персональных данных, Вы имеете право в любой момент отозвать свое согласие, изменив настройки в своей учетной записи или направив соответствующее письмо на адрес info@butlerwallet.com, указав, какое конкретно согласие Вы вызываете. Вы можете отказаться от получения материалов, направляемых нами в электронном виде, пройдя по ссылке “Отказаться от рассылки”, которая имеется в каждом нашем письме, направляемом Вам.</p>
    <p class="text__p">Право подать жалобу в надзорный орган. Вы имеете право подать жалобу на выполняемые Контролером действия по обработке персональных данных в надзорный орган по месту Вашего нахождения.</p>
    <p class="text__p">Разумный доступ к Вашим персональным данным будет предоставлен в течение месяца с даты получения Вашего требования. Если доступ не может быть предоставлен в течение вышеуказанного периода, мы сообщим Вам точную дату, когда информация будет предоставлена.</p>
    <p class="text__p">Всеми перечисленными выше правами Вы можете воспользоваться, направив соответствующее сообщение на адрес info@butlerwallet.com. Перед раскрытием Вам какой бы то ни было личной информации мы можем попросить Вас представить документ, удостоверяющий Вашу личность.</p>
    <p class="text__p">Мы можем взимать плату за предоставление доступа к персональным данным для покрытия наших собственных расходов, связанных с предоставлением Вам сведений об имеющихся в нашем распоряжении персональных данных, относящихся к Вам.</p>
    <p class="text__p">Обращаем Ваше внимание на то, что мы постараемся выполнить любые Ваши запросы, направляемые Вами в рамках реализации имеющихся у Вас прав, однако в ряде случаев могут иметься правовые или иные основания, по которым мы не можем или не обязаны выполнять такой запрос. В таком случае мы будем вынуждены отклонить Ваш запрос или выполнить его лишь частично.</p>
    <br>
    <h3 class="text__title">Подача жалобы</h3>
    <p class="text__p">В соответствии с Общим регламентом о защите персональных данных субъект данных имеет право подать жалобу в надзорный орган в государстве-члене его обычного места жительства, места работы или места предполагаемого нарушения, если субъект данных считает, что обработка относящихся к нему персональных данных нарушает указанный Регламент.</p>
    <p class="text__p">Если Вы не удовлетворены ответом, полученным от Butler, Вы можете подать жалобу в надзорный орган в государстве-члене вашего проживания. Если Вы проживаете в государстве-члене ЕС, можете найти соответствующий надзорный орган на сайте <a target="_blank" href="https://edpb.europa.eu/about-edpb/board/members_en">European Data Protection Board</a>.</p>
    <br>
    <h3 class="text__title">Изменения в нашей политике конфиденциальности</h3>
    <p class="text__p">Все изменения, которые мы можем внести в нашу Политику конфиденциальности в будущем, будут публиковаться на этой странице и, при необходимости, доводиться до Вашего сведения по электронной почте.</p>
    <br>
    <h3 class="text__title">Контактное лицо</h3>
    <p class="text__p">Мы будем рады любым вопросы, комментарии и запросы относительно нашей Политики конфиденциальности и просим направлять их нашему Инспектору по защите персональных данных на следующий адрес электронной почты info@butlerwallet.com.</p>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';

  @Component({
    page: {
      title: 'documents.policy',
    },
  })
  export default class PrivacyPolicy extends Vue {}
</script>

<style lang="scss" scoped></style>
